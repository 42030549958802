@use '@/styles/utils/mixins.scss' as *;

.servicesSectionWrapper {
  .gridContainer {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    width: 100%;
    @include mob() {
      gap: 1.25rem;
    }

    .gridRow {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 2.5rem;
      width: 100%;

      @include tab() {
        grid-template-columns: repeat(2, 1fr);
      }
      @include mob() {
        grid-template-columns: repeat(1, 1fr);
        gap: 1.25rem;
      }

      &:not(:last-child) {
        &::after {
          content: '';

          background-color: $primaryAlt;
          height: 1px;
          width: calc(100% - 3.75rem);
          margin: 0 1.875rem;
          grid-column: span 4;
          @include tab() {
            grid-column: span 2;
          }
          @include mob() {
            display: none;
          }
        }
      }

      .gridItem {
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        align-items: center;
        justify-content: center;
        padding: 2.5rem 1.875rem;
        border-radius: 1.25rem;
        background-color: $white;
        border: 1px solid $white;
        transition: all 0.2s ease-in-out;
        color: $dark;
        cursor: pointer;

        .title {
          font-size: 1.5rem;
          font-weight: 500;
        }

        .gridRadio {
          -webkit-appearance: none;
          appearance: none;
        }

        &:hover,
        &:focus {
          background-color: $neutral01;
          border-color: $primaryAlt;
          -webkit-box-shadow: 0rem 0.375rem 1rem 0rem rgba($primaryAlt, 0.35);
          -moz-box-shadow: 0rem 0.375rem 1rem 0rem rgba($primaryAlt, 0.35);
          box-shadow: 0rem 0.375rem 1rem 0rem rgba($primaryAlt, 0.35);
        }

        &:active,
        &.active {
          background-color: $primary;
          border-color: $primary;
          color: $white;
          .gridImage {
            filter: sepia(100%) brightness(200%) saturate(0%);
            -webkit-filter: sepia(100%) brightness(200%) saturate(0%);
            -moz-filter: sepia(100%) brightness(200%) saturate(0%);
          }
        }
      }

      .active {
        background-color: $primary;
        border-color: $primary;
        color: $white;

        .gridImage {
          filter: sepia(100%) brightness(200%) saturate(0%);
          -webkit-filter: sepia(100%) brightness(200%) saturate(0%);
          -moz-filter: sepia(100%) brightness(200%) saturate(0%);
        }
      }
    }
  }
}
